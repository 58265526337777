import React from "react";
import { Size } from "features/ui/components/product/product-card";

interface SizeButtonsProps {
  sizes: Size[];
  selectedSize: string;
  setSelectedSize: (size: string) => void;
}

const SizeButtons: React.FC<SizeButtonsProps> = ({
  sizes,
  selectedSize,
  setSelectedSize,
}) => {
  return (
    <>
      {sizes.map(size => (
        <button
          key={size.size}
          className={`inline-flex flex-col items-center mr-2 mb-2 px-4 py-1 border-2 rounded ${
            selectedSize === size.size ? "accent-border" : "border-gray-300"
          }`}
          onClick={() => setSelectedSize(size.size)}
        >
          <div>{size.size}</div>
          <div>${size.price}</div>
        </button>
      ))}
    </>
  );
};

export default SizeButtons;
