import React, { useState, useCallback, memo } from "react";
import { useAppState } from "features/global-state/app-context";

interface ProductSliderProps {
  images: string[];
  initialSlide?: number;
  onSlideChange?: (index: number) => void;
}

export const ProductSlider: React.FC<ProductSliderProps> = memo(({ 
  images, 
  initialSlide = 0,
  onSlideChange 
}) => {
  const stateManager = useAppState();
  const handleSlideChange = useCallback((index: number) => {
    onSlideChange?.(index);
  }, [onSlideChange]);

  return (
    <div className="relative w-full min-h-[100vw] md:min-h-[23rem] h-[33vh] sm:h-[20vh] md:h-[24vh] lg:h-[30vh] xl:h-[36vh] overflow-hidden flex flex-row">
      <div className="grid grid-cols-1 gap-2 p-3 z-10 overflow-y-scroll auto-rows-min">
        {images.map((imageId, index) => (
          <button
            key={index}
            onClick={() => handleSlideChange(index)}
            className={`border rounded overflow-hidden 
              ${initialSlide === index ? "border-4 accent-border" : "border-gray-300"}
              w-[50px] h-[66px]`}
          >
            <img
              src={stateManager.getProductImageURL(imageId)}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
              onLoad={() => {
                // console.log("Image loaded!");
              }}
              onError={event => {
                (event.target as HTMLImageElement).src =
                  "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";
              }}
            />
          </button>
        ))}
      </div>
      {/* //TODO: open modal window for selected foto */}
      <div className="relative w-full h-full flex-grow overflow-hidden">
        {images.map((imageId, index) => (
          <img
            key={index}
            src={stateManager.getProductImageURL(imageId)}
            alt={`Slide ${index + 1}`}
            className={`absolute w-full h-full object-cover transition-transform duration-500 ${
              index === initialSlide ? "translate-x-0" : "translate-x-full"
            }`}
            style={{
              transform: `translateX(${(index - initialSlide) * 100}%)`,
            }}
            onLoad={() => {
              // console.log("Image loaded!");
            }}
            onError={event => {
              (event.target as HTMLImageElement).src =
                "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";
            }}
          />
        ))}
      </div>
    </div>
  );
});

ProductSlider.displayName = 'ProductSlider';