import React, { useState, useEffect, useCallback } from "react";
import { ProductModal } from "../product-modal";
import { ProductSlider } from "./product-slider";
import { ReactComponent as LikeIcon } from "../../icons/like-mini.svg";
import { ReactComponent as StarIcon } from "../../icons/star-3.svg";
import { LoadingHTML } from "features/ui/loading";
import { useAppState, Cluster, Box, Favorite, useFavorites } from "features/global-state/app-context";
import { globalState } from "features/global-state/state-manager";
import { RouteParams } from "features/ui";
import { useParams, useNavigate } from "react-router-dom";
import { openAuthModal } from "features/ui/components/auth/auth"
import Modal from 'features/ui/components/auth/modal';
import ColorButtons from './color-buttons'

export interface Product {
  parent_asin: string;
  market: string;
  category: string;
  asin: string;
  title: string;
  brand: string;
  price: number;
  rating: number;
  reviews: number;
  sales: number;
  images: string[];
  active: boolean;
  image_id: string;
  category_size: string;
  category_color: string;
}

export interface Color {
  parent_asin: string;
  asin: string;
  image_id: string;
  color: string;
}

export interface Size {
  parent_asin: string;
  asin: string;
  size: string;
  price: number;
}

export interface ProductParent {
  market: string;
  category: string;
  parent_asin: string;
  title: string;
  brand: string;
  // price: number;
  sales: number;
  // store?: string;
  rating: number;
  reviews: number;
  colors: Color[];
  sizes: Size[];
  // images: string[];
  details: string;
  reviewsText: string;
}

export const ProductCard: React.FC = () => {
  const stateManager = useAppState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [parentProduct, setParentProduct] = useState<ProductParent | null>(null);
  const [loading, setLoading] = useState(false);
  const { slug, sectorId, clusterId } = useParams<RouteParams>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const favorites = useFavorites() || [];

  const [isModalAuth, setModalAuth] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState("");
  const [activeTab, setActiveTab] = useState("details");

  const openModal = () => setModalAuth(true);
  const closeModal = () => setModalAuth(false);
  const onConfirm = () => navigate("/auth");

  const fetchProduct = useCallback(async () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const productId = params.get("product");

    if (productId) {
      try {
        const response = await fetch(`/api/products?asin=${productId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch product");
        }
        
        const data: Product = await response.json();
        setCurrentColor(data.category_color)
        setProduct(data);

        // Получаем данные родительского продукта
        const parentResponse = await fetch(`/api/parent?parent_asin=${data.parent_asin}`);
        if (!parentResponse.ok) {
          throw new Error("Failed to fetch parent product");
        }

        const parentData: ProductParent = await parentResponse.json();
        setParentProduct(parentData);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    } else {
      console.error("Product ID is missing in the URL");
    }
  }, [window.location.search]);

  useEffect(() => {
    fetchProduct();
    // Checking the URL for the product parameter
    const urlParams = new URLSearchParams(window.location.search);
    const hasProductParam = urlParams.has('product');
    //Clearing state when unmounting a component
    return () => {
      // set the product to null only if the parameter is missing
      if (!hasProductParam) {
        setProduct(null);
        setParentProduct(null);
      }
    };
  }, [fetchProduct]);


  if (!product) {
    return <LoadingHTML />;
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const calculateNewPosition = (w: number, h: number) => {
    const innerRadius = 5;
    const outerRadius = 20;
    // Start position
    const start = { x: 0.5, y: (outerRadius - innerRadius) / 2 };
    // The set of all occupied positions taking into account the sizes of the cubes
    const occupiedPositions = new Set<string>();
    favorites.forEach(fav => {
      const favWidth = fav.w || 1;
      const favHeight = fav.h || 1;
      for (let i = 0; i < favWidth; i++) {
        for (let j = 0; j < favHeight; j++) {
          occupiedPositions.add(`${fav.x + i},${fav.y + j}`);
        }
      }
    });
    // function for checking, freedom of position depending on the size of the new cube
    const isPositionFree = (x: number, y: number, w: number, h: number) => {
      for (let i = 0; i < w; i++) {
        for (let j = 0; j < h; j++) {
          if (occupiedPositions.has(`${x + i},${y + j}`)) return false;
        }
      }
      return true;
    };
    // Spiral directions: right, up, left, down
    const lStap = w > 1 ? 1.5 : 1
    const directions = [
      { dx: lStap, dy: 0 },
      { dx: 0, dy: -lStap },
      { dx: -lStap, dy: 0 },
      { dx: 0, dy: lStap },
    ];
    let x = start.x;
    let y = start.y;

    let stepLength = 1;
    let dirIndex = 0; // direction index in the directions array
    let stepsTaken = 0;
    // Spiral placement
    while (true) {
      const offsetX = w > 1 ? 2 : 1; // Indentation for large or small cubes
      const offsetY = h > 1 ? 2 : 1;
      if (isPositionFree(x + offsetX, y + offsetY, w, h)) {
        // Taking positions for a new cube
        for (let i = 0; i < w; i++) {
          for (let j = 0; j < h; j++) {
            occupiedPositions.add(`${x + i + offsetX},${y + j + offsetY}`);
          }
        }
        return { x: x + offsetX, y: y + offsetY };
      }
      // Move to next position
      x += directions[dirIndex].dx;
      y += directions[dirIndex].dy;
      stepsTaken++;
      // If we reach the length of the current step, change direction
      if (stepsTaken === stepLength) {
        stepsTaken = 0;
        dirIndex = (dirIndex + 1) % 4;
        if (dirIndex % 2 === 0) stepLength++;
      }
      // If we go beyond the ring, stop searching
      const distance = Math.sqrt(x * x + y * y);
      if (distance > outerRadius) break;
    }
    // If no free position is found, return the initial coordinates
    return { x: 0, y: 0 };
  };

  const syncFavorites = async (newFavorites: Favorite[]) => {
    try {
      // globalState.setState("favorites", newFavorites);
      globalState.setFavorites(newFavorites);
      // Explicitly trigger the favorites change event
      globalState.emitter.emit("favoritesChange", newFavorites);
    } catch (error) {
      console.error("Error syncing favorites:", error);
      // Rollback changes in case of error
      // globalState.setState("favorites", favorites);
      globalState.setFavorites(favorites);
    }
  };

  // Handle favorite toggle
  const handleFavoriteClick = async () => {
    if (!product || loading) return;
  
    setLoading(true);
    const asin = product.asin;
    const existingFavorite = favorites.find((fav) => fav.asin === asin);
    const currentFavorites = [...favorites];
  
    try {
      if (existingFavorite) {
        // Remove from favorites
        const updatedFavorites = currentFavorites.filter(fav => fav.asin !== asin);
        syncFavorites(updatedFavorites);
  
        const response = await fetch(`/api/favorites?asin=${asin}&scene=${slug}`, {
          method: "DELETE",
          credentials: "include",
          mode: "cors",
          headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          if (response.status === 401) {
            // openAuthModal(slug, navigate); // Open modal window
            openModal()
          }
          // Rollback on error
          syncFavorites(currentFavorites);
          throw new Error("Failed to delete favorite");
        }else{
          // const currentUrl = new URL(window.location.href);
          // currentUrl.searchParams.delete('product');
          // window.history.replaceState({}, '', currentUrl);
          const params = new URLSearchParams(window.location.search);
          params.delete('product');
          navigate({ pathname: window.location.pathname, search: params.toString() });
        }

      } else {
        // Add to favorites
        const ibs = stateManager.sectors[Number(sectorId)]?.clusters[Number(clusterId)]?.ids;
        const foundItem = ibs.find((item: Box) => item.id === product.asin);
        const w = foundItem?.w || 1;
        const l = w;
        const h = foundItem?.h || 1;
        const { x, y } = calculateNewPosition(w, h);
        const newFavorite: Favorite = {
          asin,
          x,
          y,
          w,
          l,
          h,
          image_id: product.image_id,
          price: product.price,
          title: product.title,
        };
  
        const updatedFavorites = [...currentFavorites, newFavorite];
        syncFavorites(updatedFavorites);
  
        const response = await fetch("/api/favorites", {
          method: "POST",
          credentials: "include",
          mode: "cors",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            x,
            y,
            w,
            l,
            h,
            scene: slug,
            asin,
          }),
        });
  
        if (!response.ok) {
          if (response.status === 401) {
            // openAuthModal(slug, navigate); // open modal window
            openModal()
          }
          // Rollback on error
          syncFavorites(currentFavorites);
          throw new Error("Failed to add favorite");
        }
      }
    } catch (error) {
      console.error("Error handling favorite:", error);
    } finally {
      setLoading(false);
    }
  };

  const isFavorite = favorites.some(favorite =>
    favorite.asin === product.asin
  );

  return (
    <>
      <div className="h-screen bg-white rounded-lg shadow-md overflow-y-auto w-full">
        <div className="relative">
          <ProductSlider 
            images={product.images} 
            initialSlide={currentSlide} 
            onSlideChange={(index) => setCurrentSlide(index)}
          />
          <button
            onClick={() => handleFavoriteClick()}
            className={`absolute bottom-[-20px] right-4 p-2 rounded-full hover:bg-red-300
              ${loading
                ? "bg-gray-300"
                : isFavorite
                  ? "bg-red-500"
                  : "bg-white"
              }
              w-12 h-12 flex items-center justify-center
              border border-gray-300`}
          >
            <LikeIcon />
          </button>
        </div>

        <div className="p-4 h-1/2 flex flex-col justify-between">
          <div className="relative w-full mt-4 text-center">
            <div className="flex w-full justify-end mt-4">
              <span className="accent-bg text-white rounded-full px-3 py-1">
                +{product.sales} SALES
              </span>
            </div>

            <div className="flex w-full items-center justify-between space-x-4">
            <p className="text-gray-500">Visit the {product.brand} Store</p>

              <div className="flex items-center justify-center">
                <StarIcon className="text-yellow-500 w-12 h-12" />
                <span className="text-xl font-[600]"> {product.rating}</span>
                <span className="ml-2 text-gray-500">({product.reviews})</span>
              </div>
            </div>

            <h2 className="text-lg text-left font-[600] pb-4">
              {product.title}
            </h2>

            <div className="flex items-center justify-between space-x-4">
              <div className="text-4xl font-[700]">
                ${product.price.toFixed(2)}
              </div>
              <a
                href={`https://www.${product.market}.com/dp/${product.asin}`}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 accent-bg text-white rounded-full flex items-center hover:opacity-75"
              >
                {/* <LikeIcon className="mr-2" /> */}
                Go to website
              </a>
            </div>
          </div>

          <ColorButtons 
            parentProduct={parentProduct} 
            currentColor={currentColor}
            setCurrentColor={setCurrentColor}
            setCurrentSlide={setCurrentSlide}
          />

          <div className="mt-4">
            <div className="flex space-x-4">
              <button
                className={`relative py-2 pr-4 text-left font-[600] ${
                  activeTab === "details" ? "text-gray-900" : "text-gray-400"
                }`}
                onClick={() => setActiveTab("details")}
              >
                Details
                {activeTab === "details" && (
                  <span className="absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-500"></span>
                )}
              </button>
              <button
                className={`relative py-2 text-left font-[600] ${
                  activeTab === "reviews" ? "text-gray-900" : "text-gray-400"
                }`}
                onClick={() => setActiveTab("reviews")}
              >
                Reviews
                {activeTab === "reviews" && (
                  <span className="absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-500"></span>
                )}
              </button>
            </div>
            
            {parentProduct && 
              <>
              {activeTab === "details" && (
                <div className="mt-4">
                  <p className="mt-2 text-gray-500">{parentProduct.details}</p>
                </div>
              )}
              {activeTab === "reviews" && (
                <div className="mt-4">
                  <p className="mt-2 text-gray-500">{parentProduct.reviewsText}</p>
                </div>
              )}
              </>
            }
          </div>
        </div>
      </div>
      <ProductModal asin={product.asin} market={product.market} isOpen={isModalOpen} onClose={handleCloseModal} />

      <Modal isOpen={isModalAuth} onClose={closeModal} onConfirm={onConfirm}>
        <h2 className="text-lg font-bold mb-4">Authorization required</h2>
        <p className="mb-6">
          You are not authorized. Do you want to go to the login page?
        </p>
      </Modal>
    </>
  );
};
